@import url(https://cdn.rawgit.com/kattergil/NotoSerifKR-Web/76eb9ebf/stylesheet/NotoSerif-Web.css);

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("fonts/Inter-UI-Regular.woff2") format("woff2"),
       url("fonts/Inter-UI-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 400;
  src: url("fonts/Inter-UI-Italic.woff2") format("woff2"),
       url("fonts/Inter-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 600;
  src: url("fonts/Inter-UI-Medium.woff2") format("woff2"),
       url("fonts/Inter-UI-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 600;
  src: url("fonts/Inter-UI-MediumItalic.woff2") format("woff2"),
       url("fonts/Inter-UI-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter UI';
  font-style:  normal;
  font-display: auto;
  font-weight: 800;
  src: url("fonts/Inter-UI-Bold.woff2") format("woff2"),
       url("fonts/Inter-UI-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter UI';
  font-style:  italic;
  font-display: auto;
  font-weight: 800;
  src: url("fonts/Inter-UI-BoldItalic.woff2") format("woff2"),
       url("fonts/Inter-UI-BoldItalic.woff") format("woff");
}

@font-face { 
  font-family: 'Seoul Hangang';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoPeacefullA.woff') format('woff'); 
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
}

//@font-face {
//  font-family: 'Seoul Hangang';
//  font-style: normal;
//  font-display: auto;
//  font-weight: 300;
//  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
//  src: url("fonts/SeoulHangangL.woff") format("woff");
//}

//@font-face {
//  font-family: 'Seoul Hangang';
//  font-style: normal;
//  font-display: auto;
//  font-weight: 400;
//  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
//  src: url("fonts/SeoulHangangM.woff") format("woff");
//}

//@font-face {
//  font-family: 'Seoul Hangang';
//  font-style: normal;
//  font-display: auto;
//  font-weight: bold;
//  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
//  src: url("fonts/SeoulHangangB.woff") format("woff");
//}

@font-face {
  font-family: 'Seoul Hangang';
  font-style: normal;
  font-display: swap;
  font-weight: bold;
  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
  src: url("fonts/SeoulHangangEB.woff") format("woff");
}

@font-face {
  font-family: 'Nanum Barun Gothic';
  font-style: normal;
  font-display: auto;
  font-weight: normal;
  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
  src: url("fonts/NanumBarunGothicSubset.woff2") format("woff2");
}

@font-face {
  font-family: 'Nanum Barun Gothic';
  font-style: normal;
  font-display: auto;
  font-weight: bold;
  unicode-range: U+0020-U+007E,U+1100-U+11F9,U+3000-U+303F,U+3131-U+318E,U+327F-U+327F,U+AC00-U+D7A3,U+FF01-U+FF60;
  src: url("fonts/NanumBarunGothicBoldSubset.woff2") format("woff2");
}
